import React from 'react'
import ProductSectionV2 from '../../products/ProductSectionV2'
import { SessionStatusEnum } from '../../../common/enums/session-status.enum'
import { GameCategories } from '../../../common/enums/game-categories'
import FreeGameBonusHint from '../../loyalty/free/FreeGameBonusHint'
import CreditCardSectionV2 from '../../checkout/payments/CreditCardSectionV2'
import { ProductModeEnum } from '../../../common/enums/product-mode.enum'
import CustomizeButton from '../../buttons/CustomizeButton'
import OrderButton from '../../buttons/OrderButton'
import { useRecoilState } from 'recoil'
import {
  isOpenPromoCodeManagementState,
  promoState,
  selectedProductAddOnsState,
  selectedProductState,
} from '../../../common/atoms/checkout'
import { useGetMyAddOn } from '../../../hooks/users/useGetMyAddOn'
import { GameTeammatesV2 } from '../../../api/creators/getGamingTeammates'
import { useGetPromoCodesCampaigns } from '../../../hooks/stripe/useGetPromoCodesCampaigns'
import { useSession } from 'next-auth/react'
import AddonsSection from '../../products/addons/AddonsSection'
import ChampionsAddonSection from '../ChampionsAddonSection'
import { useUser } from '../../../hooks/users/useUser'

type Props = {
  gameTeammates: GameTeammatesV2
}

export default function PlayMode({ gameTeammates }: Props) {
  const [selectedAddons] = useRecoilState(selectedProductAddOnsState)
  const [promoCode] = useRecoilState(promoState)
  const { data: myAddons } = useGetMyAddOn()
  const { data: promotions } = useGetPromoCodesCampaigns()
  const [, setIsOpenPromoCodeManagement] = useRecoilState(
    isOpenPromoCodeManagementState,
  )
  const [selectedProduct] = useRecoilState(selectedProductState)
  const { status } = useSession()
  const { user } = useUser()
  return (
    <div className={'flex flex-col w-full gap-3.5'}>
      <div className={'h-fit w-full md:h-fit md:overflow-visible mt-2'}>
        <ProductSectionV2 eGirlTeammates={gameTeammates} />
      </div>

      {selectedProduct?.productAddons &&
        selectedProduct.productAddons.length > 0 &&
        ((myAddons !== undefined && myAddons !== null) ||
          status === SessionStatusEnum.UNAUTHENTICATED) && (
          <AddonsSection myAddons={myAddons} />
        )}

      {selectedProduct?.categoryKey === GameCategories.leagueOfLegends &&
        (selectedProduct?.id === 27 || selectedProduct?.id === 122) && (
          <ChampionsAddonSection categoryKey={selectedProduct?.categoryKey} />
        )}

      <div className={'flex flex-row gap-3 items-center mt-1'}>
        <h2 className={'text-body-medium text-[#F87B20]'}>Payment</h2>

        <FreeGameBonusHint />

        {!promoCode && promotions && promotions.length > 0 && (
          <button
            className={'w-fit flex flex-col'}
            onClick={(e) => {
              e.preventDefault()
              setIsOpenPromoCodeManagement(true)
            }}
          >
            <div className='border rounded-full border-bung-500 text-bung-500 px-2 py-0.5 text-xs-normal w-fit'>
              Promotions available
            </div>
          </button>
        )}
      </div>

      <CreditCardSectionV2 />

      <div className={'flex flex-col items-center gap-1.5 md:flex-row'}>
        {user?.isUsedFavoriteFeature &&
          selectedProduct?.mode !== ProductModeEnum.STREAMER && (
            <div className={'w-full md:w-1/3 xl:w-1/4 order-3 md:order-1'}>
              <CustomizeButton showAnimatedEffect={false} title={'Customize'} />
            </div>
          )}

        <div
          className={`order-1 md:order-3 
                    ${user?.isUsedFavoriteFeature && selectedProduct?.mode !== ProductModeEnum.STREAMER ? 'w-full md:w-2/3 xl:w-3/4' : 'w-full'}`}
        >
          <OrderButton
            isRematch={false}
            showAnimatedEffect={true}
            addons={selectedAddons}
          />
        </div>
      </div>
    </div>
  )
}
